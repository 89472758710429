import React from "react"
import { Helmet } from "react-helmet"
import Seo from "../seo"
import PricingCards from "./pricing-cards"
const Pricing = () => {
  return (
    <div>
      <Seo
        title="Pricing "
        description="Select the best plan for you to unleach the full potential of your IBM Maximo"
      />
      <Helmet prepend>
        <meta property="og:title" content="MAXapps Pricing" />
        <meta
          property="og:description"
          content="Select the best plan for you to unleach the full potential of your IBM Maximo ,and the best is that it is concurrent type, meaning you can have mutiple user on different shift using the same license."
        />
        <meta
          property="og:image"
          content="https://maxapps-maximo.com/og/pricing.jpg"
        />
        <meta property="og:url" content="https://maxapps-maximo.com/pricing" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@maxapps" />
        <meta name="twitter:title" content="MAXapps Pricing" />
        <meta
          name="twitter:description"
          content="Select the best plan for you to unleach the full potential of your IBM Maximo ,and the best is that it is concurrent type, meaning you can have mutiple user on different shift using the same license."
        />
        <meta
          name="twitter:image"
          content="https://maxapps-maximo.com/og/pricing.jpg"
        />
      </Helmet>
      <PricingCards />
    </div>
  )
}

export default Pricing
