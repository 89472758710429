import React, { useState } from "react"
import * as Yup from "yup"
import { Formik, Form, Field, ErrorMessage } from "formik"
import { Link, navigate } from "gatsby"

import Service from "../../../../../services"
import { getFromStorage } from "../../../../../services/utils"
import LottieAnimation from "../../../../../animations/lottie"

import "./index.scss"
const LoginForm = () => {
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("*Must be a valid email address")
      .max(100, "*Email must be less than 100 characters")
      .required("*Email is required"),
    password: Yup.string().required("*Password is required"),
  })

  const [signInError, setSignInError] = useState({
    show: false,
    msg: null,
  })

  const consoleNavigate = isAdmin => {
    if (isAdmin) {
      navigate("/console/admin/")
    } else navigate("/console/")
  }
  return (
    <div id="LoginForm" className="login-form ">
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true)

          const currentPurchaseString = localStorage.getItem("currentPurchase")
          localStorage.clear()

          const res = await Service.auth.signIn(
            values.email.toLowerCase().trim(),
            values.password
          )
          if (res.success) {
            if (currentPurchaseString) {
              const currentPurchase = JSON.parse(currentPurchaseString)
              const profile = JSON.parse(getFromStorage("profile"))

              if (!profile?.plan || currentPurchase.type === profile?.plan)
                localStorage.setItem(
                  "currentPurchase",
                  JSON.stringify({
                    ...currentPurchase,
                    user: values.email,
                  })
                )
            }

            {
              window.location.pathname === "/console/login/"
                ? consoleNavigate(res.isAdmin)
                : window.location.reload()
            }
          } else setSignInError({ show: true, msg: res.message })

          setSubmitting(false)
        }}
      >
        {({ isSubmitting, touched, errors }) => (
          <Form>
            <div className="form-input">
              <label htmlFor="Email" className="form-label">
                Work Email
              </label>
              <Field
                type="email"
                name="email"
                className={`form-control ${
                  touched.email && errors.email ? "error" : null
                }`}
              />
              <ErrorMessage
                name="email"
                component="div"
                className="error-message"
              />
            </div>
            <div className="form-input">
              <label htmlFor="Password" className="form-label">
                Password
              </label>
              <Field
                type="password"
                name="password"
                className={`form-control ${
                  touched.password && errors.password ? "error" : null
                }`}
              />
              <ErrorMessage
                name="password"
                component="div"
                className="error-message"
              />
            </div>
            <div className="pb-1 forgot-pwd">
              <Link to="/console/forgot-pwd/">Forgot password ?</Link>
            </div>
            <br />
            <div className="login-submit">
              <button
                type="submit"
                disabled={isSubmitting}
                className="form-button login-button "
              >
                Login
                {!isSubmitting && <i className="bi bi-arrow-right-short "></i>}
                {isSubmitting && (
                  <div className="login-loader">
                    <LottieAnimation height={25} width={25} />
                  </div>
                )}
              </button>
            </div>
            {signInError.show && (
              <>
                <div className="sign-in-error">
                  <i className="bi bi-exclamation-triangle-fill"></i>
                  <h6>
                    {signInError.msg}
                    {/* {signInError.msg ===
                      "This Email address is not verified" && (
                      <button
                        className="resend-pwd-link"
                        onClick={async () => {
                          await services.auth.resendVerificationLink()
                          setSignInError({
                            show: false,
                            msg: null,
                          })
                        }}
                      >
                        Resend verification Link
                      </button>
                    )} */}
                  </h6>
                </div>
              </>
            )}
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default LoginForm
