import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import PricingItem from "./pricing-item"
import PricingSticky from "./pricing-sticky"
import PricingDetails from "../pricing-details"
import YellowButton from "../../home/buttons/yellow-button"
import "./index.scss"
const PricingCards = ({ _pricngCard, _title, _mockdetail }) => {
  let pricingCards = []
  const [cardData, setCardData] = useState({})
  const [sticky, setsticky] = useState(false)
  let title = {}
  let mockdetail = {}
  try {
    const result = useStaticQuery(graphql`
      {
        mdx(fileAbsolutePath: { regex: "/(pricing-cards)/" }) {
          exports {
            pricingCard {
              btn
              list {
                imgSrc
                specification
              }
              price {
                price
                trial
              }
              subTitle
              title
              code
            }
          }
          frontmatter {
            subTitleMainPricing
            titlecolored
            blackTitle
            bottominformations
            bottomButtonTitle
          }
        }
      }
    `)

    pricingCards = result.mdx.exports.pricingCard
    title = result.mdx.frontmatter
  } catch (error) {
    pricingCards = _pricngCard
    title = _title
    mockdetail = _mockdetail
  }

  return (
    <div className="PricingCards">
      <div id="PricingCards" data-testid="PricingCards" className="">
        <div className="row justify-content-center pt-5 mt-2">
          <h1 className="mainTitle">
            {title?.blackTitle} {title?.titlecolored}
          </h1>
          <p
            className="para"
            dangerouslySetInnerHTML={{ __html: title?.subTitleMainPricing }}
          ></p>
        </div>
        <div className="row  justify-content-center py-3 ">
          {pricingCards?.map((item, index) => {
            return (
              <button
                className="col-md-5 col-xl-4 pricing-btn"
                key={index}
                data-testid={index}
                onClick={() => {
                  setCardData(item)
                  setsticky(true)
                }}
              >
                <PricingItem {...item} />
              </button>
            )
          })}
        </div>
      </div>
      <PricingDetails {...mockdetail} />

      <div className={sticky ? " PricingStickyMode " : "PricingSticky "}>
        <div className="d-md-flex  justify-content-sm-start justify-content-md-center justify-content-lg-center">
          <div
            className={
              sticky
                ? " col-sm-10 col-md-8 col-lg-12"
                : "col-sm-10 col-md-8 col-lg-12"
            }
          >
            <PricingSticky {...cardData} />
          </div>
        </div>
      </div>
      <div className=" d-flex row justify-content-center my-5 py-5">
        <div className=" col-10 text-center ">
          <span className="centeredText">{title?.bottominformations}</span>
          <div className=" mt-3 py-2">
            <YellowButton
              data={title?.bottomButtonTitle}
              size={"180px"}
              to="/contact/"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PricingCards
