import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"

import { getFromStorage, readFromStorage } from "../../../services/utils"

import "./index.scss"
const PricingSticky = props => {
  const { title, price, code } = props
  const { profile } = readFromStorage()

  const [count, setCount] = useState(1)

  const handlePurchase = () => {
    const data = {
      count,
      type: code,
      price: price?.price ? price.price : null,
    }

    try {
      const id = `payment-${Date.now()}`
      const user = getFromStorage("user")
      const userEmail = user ? JSON.parse(user).email : undefined
      const dataToStore = {
        id,
        user: userEmail,
        ...data,
      }
      localStorage.setItem("currentPurchase", JSON.stringify(dataToStore))
      if (!profile) {
        navigate(
          `/console/signup?type=purchase&count=${count}&title=${code}&price=${price?.price}`
        )
      } else {
        navigate("/console/")
      }
    } catch (error) {
      console.error("error setting up purchase data:", error)
    }
  }

  useEffect(() => {
    setCount(1)
  }, [code])
  return (
    <div id="PricingSticky">
      <div>
        <div className=" d-none d-lg-block">
          <div className="row">
            <div className="pb-3 col-lg-3 ">
              <p className="h4">
                <span>Your</span> Plan:{" "}
              </p>

              <p className="h2"> {title}</p>
            </div>

            <div className="col-lg-5 ">
              <div className="row">
                {!title && (
                  <div className=" pt-2">
                    Quantity
                    <a
                      target="_self"
                      href="#PricingItem"
                      className="choose-plan "
                    >
                      Choose Plan <i className="bi bi-arrow-up-short px-2"></i>
                    </a>
                  </div>
                )}
                <div className="row   ">
                  {code?.toLowerCase() === "diy" && (
                    <div className="d-flex justify-content-start py-2 select-button">
                      Quantity
                      <button
                        className="ms-4 "
                        onClick={() => {
                          setCount(count - 1)
                        }}
                        disabled={count > 1 ? false : true}
                      >
                        -
                      </button>
                      <strong className="counter ">
                        <input
                          type="number"
                          className="edit-input text-center"
                          value={count}
                          onChange={e => {
                            e.target.value
                              ? setCount(parseInt(e.target.value))
                              : setCount(1)
                          }}
                        />
                      </strong>
                      <button
                        className=""
                        onClick={() => {
                          setCount(count + 1)
                        }}
                      >
                        +
                      </button>
                      <div className="  trial">{title && <> +2 free</>}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className="col-6  py-2">
                {count > 0 && code?.toLowerCase() === "diy" && (
                  <>
                    Total
                    <strong className=" total-counter">{count + 2}</strong>
                  </>
                )}
              </div>
            </div>
            <div className=" col-lg-4 pt-4 p-lg-0">
              <div className=" row">
                {count > 0 && price && (
                  <div className="text-start pt-2 col-6">
                    <h6 className="">Total cost</h6>
                    <div className="">
                      <span className="total-cost">
                        {parseInt(price?.price) * count} $
                      </span>
                      /Month
                    </div>
                  </div>
                )}
                <div className="  text-end  col-6">
                  <button
                    className="select-plan-button"
                    onClick={handlePurchase}
                    disabled={
                      code?.toLowerCase() === "enterprise" ||
                      code?.toLowerCase() === "diy"
                        ? false
                        : true
                    }
                  >
                    Select this plan
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className=" d-block d-lg-none">
          <div className="">
            <p className="h4">
              <span>Your</span> Plan: <span className="title"> {title}</span>
            </p>
          </div>

          <div>
            <div className="">
              {!title && (
                <div className=" pt-2">
                  Quantity
                  <a
                    target="_self"
                    href="#PricingItem"
                    className="choose-plan "
                  >
                    Choose Plan <i className="bi bi-arrow-up-short px-2"></i>
                  </a>
                </div>
              )}
              <div className="flexln">
                {code?.toLowerCase() === "diy" && (
                  <div className="  ms-1  py-2 select-button">
                    Quantity
                    <button
                      className=" ms-3"
                      onClick={() => {
                        setCount(count - 1)
                      }}
                      disabled={count > 1 ? false : true}
                    >
                      -
                    </button>
                    <strong className="counter">
                      {" "}
                      <input
                        type="number"
                        className="edit-input text-center "
                        value={count}
                        onChange={e => {
                          e.target.value !== ""
                            ? setCount(parseInt(e.target.value))
                            : setCount(1)
                        }}
                      />
                    </strong>
                    <button
                      className="ms-2"
                      onClick={() => {
                        setCount(count + 1)
                      }}
                    >
                      +
                    </button>
                  </div>
                )}

                <div className=" trial-mobile">
                  {code?.toLowerCase() === "diy" && <> +2 free</>}
                </div>
              </div>
            </div>
            <div className="row">
              {count > 0 && code?.toLowerCase() === "diy" && (
                <div className="col-11 ms-1 py-2">
                  Total
                  <strong className=" total-counter-mobile text-center">
                    {count + 2}
                  </strong>
                </div>
              )}
            </div>

            <div className=" row">
              {count > 0 && price && (
                <div className="text-start py-4 ">
                  <h6 className="">Total cost</h6>
                  <div className="">
                    <span className="total-cost text-center col-6 col-md-2">
                      {parseInt(price?.price) * count} $
                    </span>
                    /Month
                  </div>
                </div>
              )}
            </div>
            <div className=" text-md-center text-lg-start  py-4">
              <button
                className="select-plan-button"
                onClick={handlePurchase}
                disabled={title ? false : true}
              >
                Select this plan
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PricingSticky
