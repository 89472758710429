import React, { useEffect, useState } from "react"

import Layout from "../components/layout/t1/layout"
import Pricing from "../components/pricing"
import PrivateContent from "../components/private-content"
import { isLoggedIn } from "../services/utils"

const PricingPage = () => {
  const [showPageContent, setShowPageContent] = useState(false)

  useEffect(() => {
    setShowPageContent(isLoggedIn())
  }, [])
  return <Layout>{showPageContent ? <Pricing /> : <PrivateContent />}</Layout>
}

export default PricingPage
