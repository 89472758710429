import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "./index.scss"
const PricingDetails = ({ _data, _mock }) => {
  let titleTable = {}
  let contentTable = []
  try {
    const result = useStaticQuery(graphql`
      {
        mdx(fileAbsolutePath: { regex: "/(pricing-details)/" }) {
          frontmatter {
            title
            colum1
            colum2
            description1
            description2
            informations
            buttonTitle
            buttonSize
          }
          exports {
            data {
              rowTitle
              valueCol1
              valueCol2
              link1
              link2
            }
          }
        }
      }
    `)
    titleTable = result.mdx.frontmatter
    contentTable = result.mdx.exports.data
  } catch (error) {
    titleTable = _mock
    contentTable = _data
  }

  return (
    <div
      id="pricingDetail"
      className="pricingDetail"
      data-testid="pricingDetail"
    >
      <p className="plandetails py-5">{titleTable?.title}</p>
      <div className="d-flex justify-content-center">
        <div className="box d-none d-lg-block col-10">
          <table className="table table-borderless">
            <thead>
              <tr>
                <th className="col-3 "> </th>
                <th className="col-4">
                  <p className=" h5 py-2">{titleTable?.colum1}</p>
                  <p className=" pb-2">{titleTable?.description1}</p>
                </th>
                <th className="col-4">
                  <p className=" h5 py-2">{titleTable?.colum2}</p>
                  <p className=" pb-2">{titleTable?.description2}</p>
                </th>
              </tr>
            </thead>
            <tbody>
              {contentTable?.map((item, index) => {
                return (
                  <tr className="border-top " key={index}>
                    <th className="row">
                      <p className="h6">{item.rowTitle}</p>
                    </th>
                    <td>
                      <p
                        dangerouslySetInnerHTML={{ __html: item.valueCol1 }}
                      ></p>
                      {item.link1 && (
                        <p dangerouslySetInnerHTML={{ __html: item.link1 }}></p>
                      )}
                      {item.link2 && (
                        <p dangerouslySetInnerHTML={{ __html: item.link2 }}></p>
                      )}
                    </td>
                    <td
                      dangerouslySetInnerHTML={{ __html: item.valueCol2 }}
                    ></td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="box d-block d-lg-none">
        <div className="text-center">
          <p className="h5 py-2">{titleTable?.colum1}</p>
          <p className=" pb-2">{titleTable?.description1}</p>
        </div>
        <table className="table table-borderless">
          <tbody>
            {contentTable?.map((item, index) => {
              return (
                <tr className="border-top " key={index}>
                  <th className="row">
                    <p className="rowtitle">{item.rowTitle}</p>
                  </th>
                  <td>
                    <p dangerouslySetInnerHTML={{ __html: item.valueCol1 }}></p>
                    {item.link1 && (
                      <p dangerouslySetInnerHTML={{ __html: item.link1 }}></p>
                    )}
                    {item.link2 && (
                      <p dangerouslySetInnerHTML={{ __html: item.link2 }}></p>
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <div className="text-center">
          <p className="h5 py-2">{titleTable?.colum2}</p>
          <p className=" pb-2">{titleTable?.description2}</p>
        </div>
        <table className="table table-borderless">
          <tbody>
            {contentTable?.map((item, index) => {
              return (
                <tr className="border-top " key={index}>
                  <th className="row">
                    <p className="rowtitle">{item.rowTitle}</p>
                  </th>
                  <td className="">{item.valueCol2}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default PricingDetails
