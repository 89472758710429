import React from "react"
import { Link } from "gatsby"
import "./index.scss"

const PricingItem = props => {
  const { title, subTitle, price, list, btn } = props
  // try {
  //   const result = useStaticQuery(graphql`
  //     {
  //       mdx(fileAbsolutePath: { regex: "/(pricing-cards)/" }) {
  //         frontmatter {
  //           titleButton
  //         }
  //       }
  //     }
  //   `)

  //   const titleButton = result.mdx.frontmatter.titleButton
  // } catch (error) {
  //   const titleButton = _cardInfo
  // }
  return (
    <div
      id="PricingItem"
      className="PricingItem py-5 m-2"
      data-testid="PricingItem"
    >
      {price && (
        <div className="title d-flex justify-content-center">
          <div className="">
            <h2 className="titleStyle py-1">{title}</h2>
          </div>
          {/* <div className="">
            <p className="trial">{price.trial}</p>
            <p className="price ">
              <span className="prix">{price.price}</span>/month
            </p>
          </div> */}
        </div>
      )}

      {btn && (
        <div className="title ">
          <div className="d-flex justify-content-center">
            <h2 className="titleStyle py-1">{title}</h2>
          </div>
          {/* <div className="col-5 btnContainer  pt-1">
            <div className=" yellow-btn-text">
              <Link to="/contact/">
                <div type="button" className="btn  ">
                  {btn}
                </div>
              </Link>
            </div>
          </div> */}
        </div>
      )}
      <div className=" d-flex justify-content-center">
        <p className=" col-9 description ">{subTitle}</p>
      </div>
      {btn && (
        <div className=" animted-content-inner d-flex justify-content-center mt-2 mb-4">
          <Link to="/contact/">
            <div className="button-link-wrap  ">
              <p>{btn}</p>
            </div>
          </Link>
        </div>
      )}
      {price && (
        <div className=" animted-content-inner d-flex justify-content-center mt-2 mb-4">
          <Link to="/get-started/">
            <div className="button-link-wrap  ">
              <p>Get Started</p>
            </div>
          </Link>
        </div>
      )}
      {price && (
        <div className="pricing-price-wrap">
          <p className="freeTrial mx-4">{price.trial}</p>
          <p className="price ">
            <span className="price-prefix-text">$</span>
            <span className="pricing-price">{price.price}</span>
            <span className="price-postfix-text pt-3">per month</span>
          </p>
        </div>
      )}
      {btn && (
        <div className="pricing-price-wrap entreprise-price">
          <div className="entreprise-price">
            <p>Get quote</p>
          </div>
        </div>
      )}
      <div className=" lisContainer py-3 ">
        <div className="myList d-flex  row ">
          <div className="col-11 col-lg-10">
            {list.map((item, index) => {
              return (
                <li key={index} className="d-flex text-right  p-0 ">
                  <img
                    alt={`IBM Maximo: ${item.specification}`}
                    title={`IBM Maximo: ${item.specification}`}
                    src={item.imgSrc}
                    className="img-icon  img-fluid p-0  mx-2 "
                  />
                  <p className="ligne p-0 m-1"> {item.specification}</p>
                </li>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PricingItem
